<template>

  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="date_from">{{ $t('administration.table.fields.date_between') }}</label>
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            :locale-data="options"
            single-date-picker="range"
            :auto-apply="false"
            style="width: 100%"
            @update="fetchTable()"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="tableData"
        responsive
        :fields="tableColumns"
        primary-key="date"
        show-empty
        :empty-text="$t('administration.table.settings.empty_text')"
      >
        <template #cell(total)="data">
          {{ `${data.item.total} грн` }}
        </template>
      </b-table>
    </b-card>
    <br>
    <br>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="tableTotalData"
        responsive
        :fields="tableColumns"
        primary-key="date"
        show-empty
        :empty-text="$t('administration.table.settings.empty_text')"
      >
        <template #cell(total)="data">
          {{ `${data.item.total} грн` }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import SweetAlertMixin from '@mixins/SweetAlertMixin'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    DateRangePicker,
  },
  mixins: [SweetAlertMixin],
  props: {
    frame: {
      type: [Boolean, null],
      default: null,
    },
  },
  data() {
    return {
      options: {
        firstDay: 1,
        format: 'dd-mm-yyyy',
      },
      tableData: null,
      tableTotalData: null,
      dateRange: {},
      tableColumns: [
        { key: 'date', label: '', sortable: false },
        { key: 'quantity', label: this.$t('administration.table.fields.quantity'), sortable: false },
        { key: 'total', label: this.$t('administration.table.fields.total'), sortable: false },
      ],
    }
  },
  methods: {
    fetchTable() {
      this.$http.get('/api/administration/order_stat/', {
        params: {
          start_date: this.dateRange.startDate,
          end_date: this.dateRange.endDate,
        },
      })
        .then(response => {
          this.tableData = response.data.data
          this.tableTotalData = response.data.total
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    setDate(ev) {
      this.$emit('update:startDate', ev.startDate)
      this.$emit('update:endDate', ev.endDate)
      // this.startDate = this.dateRange.startDate
      // this.endDate = this.dateRange.endDate
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.daterangepicker.openscenter {
  width: max-content !important;
}
</style>
